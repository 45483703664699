import React from "react"

// logos testimonios
import KontigoTes from "@components/sharedComponents/testimonies/images/logos/kontigo.svg"
import SavarTes from "@components/sharedComponents/testimonies/images/logos/savar.svg"
import CepebanTes from "@components/sharedComponents/testimonies/images/logos/cepeban.svg"
import CasaRosselloTes from "@components/sharedComponents/testimonies/images/logos/casaRossello.svg"
import AstaraTes from "@components/sharedComponents/testimonies/images/logos/astara.svg"

//foto testimonio desktop
import kontigoDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/kontigo.png"
import savarDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/savar.png"
import cepebanDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/cepeban.png"
import astaraDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/astara.png"
import casaRosselloDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/casaRossello.png"

//foto testimonio desktop
import kontigoMobile from "@components/sharedComponents/testimonies/images/fotosMobile/kontigo.png"
import savarMobile from "@components/sharedComponents/testimonies/images/fotosMobile/savar.png"
import cepebanMobile from "@components/sharedComponents/testimonies/images/fotosMobile/cepeban.png"
import astaraMobile from "@components/sharedComponents/testimonies/images/fotosMobile/astara.png"
import casaRosselloMobile from "@components/sharedComponents/testimonies/images/fotosMobile/casaRossello.png"

//iconos de precio
import advanced from "@components/pageThanks/pageThanksTicker/components/silderPricing/image/digital.png"
import business from "@components/pageThanks/pageThanksTicker/components/silderPricing/image/digitalVoice.png"


//color checked precio
import amarillo from "@components/pageThanks/pageThanksTicker/components/silderPricing/image/amarillo.svg"
import azulino from "@components/pageThanks/pageThanksTicker/components/silderPricing/image/azulino.svg"
import verde from "@components/pageThanks/pageThanksTicker/components/silderPricing/image/verde.svg"

import imageStart from "@components/pageTicker/pageSolucionOmnicanal/image/start.png"

//iconos de funcion
import tododia from "@components/pageTicker/pageSolucionOmnicanal/image/card1.png"
import mensaje from "@components/pageTicker/pageSolucionOmnicanal/image/card2.png"
import cobranza from "@components/pageTicker/pageSolucionOmnicanal/image/card3.png"
import automatizacion from "@components/pageTicker/pageSolucionOmnicanal/image/card4.png"

import card1 from "@components/pageTicker/pageSolucionOmnicanal/image/imgCard1.png"
import card2 from "@components/pageTicker/pageSolucionOmnicanal/image/imgCard2.png"
import card3 from "@components/pageTicker/pageSolucionOmnicanal/image/imgCard3.png"
import card4 from "@components/pageTicker/pageSolucionOmnicanal/image/imgCard4.png"
import card5 from "@components/pageTicker/pageSolucionOmnicanal/image/imgCard5.png"



const title1 = (
  <p className="guide__title">
    Plataforma omnicanal para tu empresa
  </p>
)

const TextBanner1 = (
  <p>
    Si buscas una solución omnicanal para tu empresa, estás en el lugar indicado. Con Beex, integras todos tus canales digitales en un solo lugar para brindar la mejor atención a tus clientes. </p>
)



const data = {
  start: {
    title: title1,
    textbody: TextBanner1,
    image: imageStart,
    textButton: "¡Empieza ahora!",
    link: "/hablemos",
  },
  useCases: {
    title: <>Lo que hará Score con tu Contact Center</>,
    description: "",
  },
  modules: [
    {
      title: "Unifica los canales preferidos",
      img: tododia,
      classtitle: "card-title-inbox",
      status: "Disponible",
      class: "texto-espacio",
      info:
        "Integra los canales más importantes para tus clientes como WhatsApp, Facebook e Instagram.",
    },
    {
      title: "Permite todas las integraciones",
      classtitle: "card-title-inbox",
      img: mensaje,
      info:
        "Su estructura de APIs permite integrarse a las herramientas que utilices, como tu CRM.",
      status: "Disponible",
      class: "texto-espacio",
    },
    {
      title: "Marcadores Inteligentes",
      classtitle: "card-title-inbox",
      img: cobranza,
      info:
        "Tanto marcadores predictivos como progresivos, ambos trabajan con Machine Learning, lo que te permite automatizar tus campañas en pocos minutos.",
      status: "Disponible",
      class: "texto-espacio",
    },
    {
      title: "Enruta y supervisa en vivo",
      classtitle: "card-title-inbox",
      img: automatizacion,
      info:
        "Crea enrutamientos inteligentes de los tickets de atención y supervisa en tiempo real a tus agentes.",
      status: "Disponible",
      class: "texto-espacio",
    },
  ],
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
  testimonies: [
    {
      image: KontigoTes,
      title:
        "Con Beex, somos capaces de cerrar el 98% de las consultas en primer contacto",
      textbody:
        "Kontigo, fintech respaldada por Mastercard, automatiza su atención al cliente omnicanal, con chatbots por WhatsApp que brindan atención 24x7.",
      name: "Analucía Siguas Velásquez",
      post: "Gerente de Marketing y Producto",
      link: "/casos-de-exito/kontigo/",
      imgDesktop: kontigoDesktop,
      imgMobile: kontigoMobile,
      metrics: [
        {
          metric: "98%",
          descripcion: "casos cerrados en primer contacto",
          color: "#FFB800",
        },
        {
          metric: "<1 minuto",
          descripcion: "tiempo de primera respuesta",
          color: "#5831D8",
        },
        {
          metric: "x3",
          descripcion: "mejoró el tiempo de resolución de casos",
          color: "#00CA56",
        },
      ],
    },
    {
      image: CasaRosselloTes,
      title:
        "Con Beex, hemos aumentado en +75% nuestros leads desde canal digital",
      textbody:
        "Casa Rosselló, compañía con más de 150 años de historia en el sector acabados y construcción, prospecta de forma más óptima con WhatsApp API y livechat.",
      name: "Marlen Vega",
      post: "Jefa de Marketing",
      link: "/casos-de-exito/casa-rossello/",
      imgDesktop: casaRosselloDesktop,
      imgMobile: casaRosselloMobile,
      metrics: [
        {
          metric: "+75%",
          descripcion: "aumento de leads digitales",
          color: "#FFB800",
        },
        {
          metric: "+65%",
          descripcion: "aumento de recompra",
          color: "#5831D8",
        },
      ],
    },
    {
      image: AstaraTes,
      title:
        "Con Beex, triplicamos nuestras ventas y reducido el esfuerzo de remarketing en 93%",
      textbody:
        "Astara, concesionaria de automóviles con presencia en Latam, ha automatizado sus procesos de marketing y ventas para canal orgánico y de paga con WhatsApp API.",
      name: "Jonny Castillo",
      post: "Jefe Comercial",
      link: "/casos-de-exito/astara/",
      imgDesktop: astaraDesktop,
      imgMobile: astaraMobile,
      metrics: [
        {
          metric: "x3",
          descripcion: "aumentó sus ventas digitales",
          color: "#FFB800",
        },
        {
          metric: "93%",
          descripcion: "redujo su esfuerzo para remarketing",
          color: "#5831D8",
        },
        {
          metric: "80%",
          descripcion: "mejoró contactabilidad con leads",
          color: "#00CA56",
        },
      ],
    },
    {
      image: SavarTes,
      title:
        "Con Beex, mejoramos +20% la satisfacción de nuestros clientes en entregas de pedidos",
      textbody:
        "Savar, empresa líder en logística, ha mejorado sus métricas más importantes de satisfacción notificando sobre el estado de sus envíos por WhatsApp.",
      name: "Dinkar Rios Macedo",
      post: "Jefe de Sistemas",
      link: "/casos-de-exito/savar/",
      imgDesktop: savarDesktop,
      imgMobile: savarMobile,
      metrics: [
        {
          metric: "+20%",
          descripcion: "aumentó la satisfacción de sus clientes",
          color: "#FFB800",
        },
        {
          metric: "+30%",
          descripcion: "se redujeron las consultas repetitivas",
          color: "#5831D8",
        },
        {
          metric: "25%",
          descripcion: "mejoró el tiempo de resolución de casos",
          color: "#00CA56",
        },
      ],
    },
    {
      image: CepebanTes,
      title: "Con Beex y el API de WhatsApp mejoramos nuestra tasa de conversión de leads en +27%",
      textbody:
        "CEPEBAN, instituto especializado en banca y finanzas, ha aumentado a +1.5k la cantidad de leads mejor prospectados con chatbots por WhatsApp Business API.",
      name: "Alan De La Cruz",
      post: "Jefe de Sistemas",
      link: "/casos-de-exito/cepeban/",
      imgDesktop: cepebanDesktop,
      imgMobile: cepebanMobile,
      metrics: [
        {
          metric: "+27%",
          descripcion: "mejoró su tasa de conversión con sus leads",
          color: "#FFB800",
        },
        {
          metric: "+1.5k",
          descripcion: "leads prospectados al mes por chatbots",
          color: "#5831D8",
        },
        {
          metric: "25%",
          descripcion: "mejoró el tiempo de resolución de casos",
          color: "#00CA56",
        },
      ],
    },
    {
      title: "conoce",
      metrics: [
      ],
    },
  ],
  pricing: [
    {
      title: "DIGITAL",
      price: (
        <p className="pricing-number">
          $40.00 <span className="perUser">POR USUARIO</span>
        </p>
      ),
      typePrice: "yes",
      classtitle: "card-title-inbox",
      img: advanced,
      info: [
        "5 usuarios mínimos al mes",
        "Todos los canales digital disponibles",
        "Chatbots ilimitados",
        "Campañas masivas de WhatsApp, SMS y correo",
        "Tickets y contactos ilimitados",
        "Acceso a automatizaciones, API e IA",
      ],
      link:
        "https://app.beexconv.com/signup?plan=611fd46372992c4324a281d4&license=advanced",
      color: "#f7d731",
      checked: amarillo,
    },
    {
      title: "DIGITAL + VOICE",
      price: (
        <p className="pricing-number" style={{ color: "#4894CD" }}>
          $60.00 <span className="perUser">POR USUARIO</span>
        </p>
      ),
      typePrice: "yes",
      classtitle: "card-title-inbox",
      img: business,
      info: [
        "10 usuarios mínimos al mes",
        "Canales digitales y de voz disponibles",
        "Chatbots ilimitados",
        "Campañas masivas de WhatsApp, SMS y correo",
        "Tickets y contactos ilimitados",
        "Acceso a automatizaciones, API e IA",
      ],
      link:
        "https://app.beexconv.com/signup?plan=611fd46372992c4324a281d5&license=business",
      color: "#f7d731",
      checked: azulino,
    },
  ],
  cardsText: {
    title: <>Accede a la API de WhatsApp sin costo alguno</>,
    description: "Con Ticker podrás acceder a la API de WhatsApp sin costo alguno, solo tendrás que pagar por lo que consumes. Con esta herramienta podrás:",
  },
  cards: [card1, card2, card3, card4, card5],

}

export default data
